import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateMhp } from '../../actions/mhps';
import './AdminMhpCard.scss';

function AdminMhpCard({ mhp }) {
  // console.log(mhp);
  const [isVerified, setIsVerified] = useState(mhp.verificationStage);
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();

  const changeMHPStatus = () => {
    if (isVerified === 'verified') {
      dispatch(updateMhp(mhp.id, 'registered'));
      setIsVerified('registered');
    } else {
      dispatch(updateMhp(mhp.id, 'verified'));
      setIsVerified('verified');
    }
  };

  const bioText = expand ? mhp.bio : mhp.bio.substr(0, 200);

  return (
    <div className="Admin">
      <div className="Admin__wrapper">
        <div className="Admin__block-about">
          <h1>{mhp.displayName}</h1>
          <div className="Admin__block-email">
            <p>{mhp.email}</p>
          </div>
          <div className="Admin__block-email">
            {/* <p>{mhp.bio.length > 100 ? mhp.bio.substr(0, 100) : mhp.bio}</p> */}
          </div>
        </div>
        <div className="Admin_status">
          <button onClick={() => changeMHPStatus()}>{isVerified}</button>
        </div>
      </div>
      <div className="Admin__bio">
        {mhp.bio.length < 200 && (
          <span>{bioText}</span>
        )}
        {mhp.bio.length > 200 && (
          <>
            {bioText}
            <text style={{ color: '#0000EE', cursor: 'pointer' }} className="Admin__readmore" onClick={() => setExpand(!expand)}>{expand ? ' read less...' : ' ...read more'}</text>
          </>
        )}
        {/* {mhp.bio.substr(0, 150)} */}
      </div>
    </div>
  );
}
export default AdminMhpCard;
