import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { LOGOUT } from '../../constants/actionTypes';
import AdminMhpCard from '../../components/AdminMhpCard/AdminMhpCard';
import './Admin.scss';

function Admin({ enqueueSnackbar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* eslint-disable-next-line */
  const [reg, setReg] = useState([]);
  /* eslint-disable-next-line */
  const [ver, setVer] = useState([]);

  const logout = () => {
    dispatch({ type: LOGOUT });
    enqueueSnackbar('Successfully logged out!');
    navigate('/auth');
  };

  const { mhps } = useSelector((state) => state.mhps);

  useEffect(() => {
    sort();
  }, []);

  const sort = () => {
    /* eslint-disable-next-line */
    mhps.filter((el) => {
      console.log(el.verificationStage);
      if (el.verificationStage === 'registered') {
        setReg((prevState) => [...prevState, el]);
      } else {
        setVer((prevState) => [...prevState, el]);
      }
    });
  };

  console.log(reg);

  return (
    <div className="admin__profile">
      <div className="admin__logout">
        <button className="btn-submit" onClick={logout}>Logout</button>
      </div>
      <div className="admin__profile-body">
        <h2>MHPs</h2>
        <div className="admin__profile-title">
          <p>Name</p>
          <p>Status</p>
        </div>
        <div className="admins__status">
          {reg.map((mhp) => <AdminMhpCard mhp={mhp} key={mhp.id} />)}
          {ver.map((mhp) => <AdminMhpCard mhp={mhp} key={mhp.id} />)}
        </div>
      </div>
    </div>
  );
}
export default withSnackbar(Admin);
