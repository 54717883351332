import React from 'react';

function WalletPage() {
  return (
    <div className="schedulePage">
      <div className="walletPage" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', fontSize: '40px', fontWeight: '800' }}>
        Coming Soon!
      </div>
    </div>
  );
}

export default WalletPage;
